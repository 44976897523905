.css-1yxbss3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-cr73ry-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: none !important;
  border-radius: 0px !important;
  width: 100px;

  // .Mui-selected {
  //   background-color: unset !important;
  // }
}

// .css-1uec5xn-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
//   background-color: inherit;
// }
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0px;
}

// .Mui-selected {
//   background-color: 0;
// }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 black;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 20px;
  border: 3px solid black;
}

// Onboard cards - color by task status
.status-new {
  //border: 3px solid #e62f2f !important;

  .css-gfu5c-MuiTypography-root {
    background-color: #e62f2f;
  }
}

.status-draft {
  // border: 3px solid #e6c72f !important;

  .css-gfu5c-MuiTypography-root {
    background-color: #e6c72f;
    color: black;
  }
}

.status-completed {
  // border: 3px solid #8fc463 !important;
  .css-gfu5c-MuiTypography-root {
    background-color: #8fc463;
    color: black;
  }
}

.css-gfu5c-MuiTypography-root {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.card-action-button {
  background-color: #e4e4e4;
  color: black;
}

.card-action-button:hover {
  background-color: darken(#e4e4e4, 10%);
  color: black;
}

.card-action-link {
  text-decoration: none;

  &:hover {
    color: black;
  }
}

.css-j65emk-MuiButtonBase-root-MuiButton-root {
  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.css-1of52hn-MuiButtonBase-root-MuiButton-root {
  background-color: #e4e4e4;
  color: black;
}

.css-1of52hn-MuiButtonBase-root-MuiButton-root:hover {
  background-color: darken(#e4e4e4, 10%);
  color: black;
}

a {
  text-decoration: none;
}

.cube-face {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.cube-face.used {
  background-color: #6ebd44;
}

.cube-face.unused {
  background-color: #ccc;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.assets-list {
  a {
    font-size: 1.4rem;
    color: #1890ff;
  }

  a:hover {
    color: #6ebd44;
  }
}

a,
a:hover,
a:visited {
  color: #177ddc;
  text-decoration: none;
}

.css-b2yv8r-MuiCardMedia-root {
  display: none;
}

.css-518kzi-MuiListItemSecondaryAction-root {
  top: 75%;
}

.htmlComment {
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.custom-card-menu {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 60px;
  width: 100px;
  background-color: #5d5d5d;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    border-bottom: 1px solid black;
  }

  li:hover {
    background-color: #111;
  }

  button {
    cursor: pointer;
    background-color: inherit;
    border: none;
    font-size: 14px;
    color: white;
    padding: 10px;
    width: 100%;
    height: 100%;
  }
}
